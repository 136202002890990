import PropTypes from 'prop-types';
import React from 'react';
import Config from '../../app.config';

const sizes = {
  small: {
    maxWidth: '32px',
    maxHeight: '32px',
  },
  normal: {
    maxWidth: '64px',
    maxHeight: '64px',
  },
  big: {
    maxWidth: '106px',
    maxHeight: '106px',
  },
};
const SvgIcon = props => {
  const style = props.size ? sizes[props.size] : {};

  return (
    <svg className={props.className} style={style}>
      <use
        xlinkHref={`${Config.awsCloudFrontUrl}/svg/sprite-icons.svg#${
          props.icon
        }`}
      />
    </svg>
  );
};

SvgIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default SvgIcon;
