import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import { getCookie, lsTest } from 'utils/localstore';
import LoadingScreen from '../../components/LoadingIndicator';
import { segmentIdentify } from '../../utils/common';
import {
  fetchAppointments,
  fetchLocations,
  fetchStatus,
  getIpAddress,
} from '../Appointment/actions';
import appointmentSaga from '../Appointment/saga';
import makeSelectAppointment from '../Appointment/selectors';
import { fetchMembershipDetails } from '../Membership/actions';
import membershipSaga from '../Membership/saga';
import makeSelectMembership from '../Membership/selectors';
import { fetchUser } from './actions';
import sagas from './sagas';
import { makeSelectUser } from './selectors';

class User extends Component {
  constructor(props) {
    super(props);
    this.props.getIpAddress();
    this.state = {
      isUserFetched: false,
      fetching: false,
    };
  }

  componentDidMount() {
    // const { membershipDetails } = this.props.membershipData;
    // const { userData } = this.props.user;
    let userId;
    if (lsTest()) {
      userId = Number(localStorage.getItem('userId'));
    } else {
      userId = Number(getCookie('userId'));
    }
    if (userId === 0 || Number.isNaN(userId)) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ isUserFetched: true });
    } else {
      this.props.fetchUser(this.props.history, true);
      // this.props.fetchMembershipDetails();
      this.props.fetchAppointments();
      this.props.fetchStatus();
      this.props.fetchLocations();
    }
    if (isEmpty(localStorage.getItem('userData'))) {
      segmentIdentify(localStorage.getItem('userData'));
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.fetching === false && prevState.fetching === true) {
      return { isUserFetched: true, fetching: nextProps.user.fetching };
    }
    return { fetching: nextProps.user.fetching };
  }

  render() {
    if (
      this.props.user.fetching ||
      !this.state.isUserFetched ||
      this.props.appointmentData.fetchingUserAppointments
    ) {
      return <LoadingScreen hangTight="true" />;
    } else if (this.props.user.error) {
      return (
        <div>
          <p>
            <span>Fetch user failed:</span> {this.props.user.error}
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

User.propTypes = {
  fetchUser: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  user: PropTypes.object,
  history: PropTypes.object,
  appointmentData: PropTypes.object,
  fetchAppointments: PropTypes.func,
  fetchStatus: PropTypes.func,
  fetchLocations: PropTypes.func,
  getIpAddress: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  membershipData: makeSelectMembership(),
  appointmentData: makeSelectAppointment(),
});

const withConnect = connect(
  mapStateToProps,
  {
    fetchUser,
    fetchMembershipDetails,
    fetchAppointments,
    fetchStatus,
    fetchLocations,
    getIpAddress,
  },
);

const withSagaFetchUser = injectSaga({
  key: 'fetchUser',
  saga: sagas.watchFetchUser,
});

const withSagaSignupUser = injectSaga({
  key: 'signupUser',
  saga: sagas.watchSignup,
});

const withSagaLogin = injectSaga({ key: 'userLogin', saga: sagas.watchLogin });
const withSagaCreateUserCard = injectSaga({
  key: 'userCard',
  saga: sagas.watchCreateUserCard,
});
const withSagaUpdateUserCard = injectSaga({
  key: 'updateUserCard',
  saga: sagas.watchUpdateUserCard,
});

const withSagaFetchUserCards = injectSaga({
  key: 'fetchUserCards',
  saga: sagas.watchFetchUserCards,
});

const withSagaLogout = injectSaga({
  key: 'userLogout',
  saga: sagas.watchLogout,
});

const withSagaFetchMembershipDetails = injectSaga({
  key: 'membershipDetails',
  saga: membershipSaga.watchFetchMembershipDetails,
});
const withSagaForgotPassword = injectSaga({
  key: 'userForgotPassword',
  saga: sagas.watchForgotPassword,
});

const withSagaResetPassword = injectSaga({
  key: 'userResetPassword',
  saga: sagas.watchResetPassword,
});

const withSagaUpdateUser = injectSaga({
  key: 'userUpdate',
  saga: sagas.watchUpdateUser,
});

const withSagaFetchAppointments = injectSaga({
  key: 'fetchAppointments',
  saga: appointmentSaga.watchFetchAppointments,
});

const withSagaFetchStatus = injectSaga({
  key: 'fetchStatus',
  saga: appointmentSaga.watchFetchStatus,
});

const withSagaChangePassword = injectSaga({
  key: 'userChangePassword',
  saga: sagas.watchChangePassword,
});

const withSagaPurchaseMembership = injectSaga({
  key: 'purchaseMembership',
  saga: membershipSaga.watchPurchaseMembership,
});

const withSagaFetchLocations = injectSaga({
  key: 'fetchLocations',
  saga: appointmentSaga.watchFetchLocations,
});

const withSagaGetIpAddress = injectSaga({
  key: 'getIpAddress',
  saga: appointmentSaga.watchGetIpAddress,
});

export default compose(
  withRouter,
  withSagaFetchUser,
  withSagaSignupUser,
  withSagaLogin,
  withSagaLogout,
  withSagaFetchUserCards,
  withSagaCreateUserCard,
  withSagaUpdateUserCard,
  withSagaFetchMembershipDetails,
  withSagaForgotPassword,
  withSagaResetPassword,
  withSagaUpdateUser,
  withSagaFetchAppointments,
  withSagaChangePassword,
  withSagaPurchaseMembership,
  withSagaFetchStatus,
  withSagaFetchLocations,
  withSagaGetIpAddress,
  withConnect,
)(User);
