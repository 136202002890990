/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import '../../css/promomessage.css';

function check(props) {
  if (props.passedProps.urlArr.indexOf(props.location.pathname) >= 0) {
    return true;
  }
  return false;
}

function PromoMessage(props) {
  const shouldShow = check(props);
  return (
    <div className={shouldShow ? 'promoCont' : 'hide'}>
      <span className="tagLine">{props.passedProps.tagLine}&nbsp; </span>
      <Link
        aria-label="promo-link-text"
        to={props.passedProps.redirectTo}
        className="linkText"
      >
        {props.passedProps.linkText}
      </Link>
    </div>
  );
}

PromoMessage.propTypes = {
  passedProps: PropTypes.object,
};

export default withRouter(PromoMessage);
