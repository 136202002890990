import axios from 'axios';
import _includes from 'lodash/includes';
import { getCookie, lsTest } from 'utils/localstore';
import 'whatwg-fetch';
import { ALLOWED_ORIGINS } from '../containers/Preferences/constants';
// import 'whatwg-fetch';
const CONTENT_TYPE = 'application/json';

const getAuthToken = () => {
  let authToken;
  if (lsTest()) {
    authToken = localStorage.getItem('userToken');
  } else {
    authToken = getCookie('userToken');
  }
  return authToken;
};

export function buildRequestObj(
  method = 'GET',
  body = {},
  isAuthNeeded = false,
) {
  const requestOptions = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': CONTENT_TYPE,
    },
  };

  if (_includes(['POST', 'PUT', 'DELETE'], method)) {
    requestOptions.body = body;
  }

  if (isAuthNeeded) {
    requestOptions.headers.Authorization = getAuthToken();
  }

  return requestOptions;
}

export function buildRequestTicketingImage(
  method = 'PUT',
  body = {},
  isAuthNeeded = false,
) {
  const requestOptions = {
    method,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const formData = new FormData();

  if (body.avatar) {
    const size = body.avatar.length;
    let i;
    for (i = 0; i < size; i += 1) {
      if (body.blobs[i]) {
        formData.append('file', body.blobs[i], `image${i}.jpeg`);
      } else {
        formData.append('file', body.avatar[i], `image${i}.jpeg`);
      }
    }
  }
  formData.append('comment', body.comment);

  if (_includes(['POST', 'PUT', 'DELETE'], method)) {
    requestOptions.body = formData;
  }
  if (isAuthNeeded) {
    let authToken;
    if (lsTest()) {
      authToken = localStorage.getItem('userToken');
    } else {
      authToken = getCookie('userToken');
    }
    requestOptions.headers.Authorization = authToken;
  }
  return requestOptions;
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return {};
  }
  return response.data;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

// const refreshAuthLogic = async originalRequestError => {
//   const refreshTokenUrl = `${Config.apiBase}/customer/refresh`;

//   const res = await axios({
//     url: refreshTokenUrl,
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': CONTENT_TYPE,
//       Authorization: getAuthToken(),
//     },
//   });

//   const userToken = res.token.replace('Bearer', '');

//   if (lsTest()) {
//     localStorage.setItem('userToken', userToken);
//     localStorage.setItem('userTokenCreatedAt', new Date());
//   } else {
//     setCookie('userToken', userToken, 30);
//     setCookie('userTokenCreatedAt', new Date().toUTCString(), 30);
//   }

//   originalRequestError.response.config.headers.Authorization = userToken; // eslint-disable-line no-param-reassign
//   return Promise.resolve();
// };
/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  const method = options.method.toLowerCase();
  const { headers } = options;
  const data = options.body;
  const corsDomainConfig = {
    headers: {
      'Access-Control-Allow-Origin': ALLOWED_ORIGINS.join(', '),
    },
  };

  // if (headers.Authorization) {
  //   const instance = axios.create();
  //   createAuthRefreshInterceptor(instance, refreshAuthLogic);
  //   return instance({
  //     method,
  //     url,
  //     timeout: 30000,
  //     headers,
  //     data,
  //   })
  //     .then(checkStatus)
  //     .then(parseJSON);
  // }
  return axios({
    method,
    url,
    timeout: 30000,
    headers,
    data,
    corsDomainConfig,
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      const { pathname } = window.location;
      const loginPage =
        pathname === '/login' || pathname === '/membership/login';
      if (error.response.status === 401 && !loginPage) {
        localStorage.clear();
        sessionStorage.clear();
        // eslint-disable-next-line no-unused-expressions
        window && window.location && window.location.replace('/login');
      } else {
        throw error;
      }
    });
}
