/* eslint-disable no-console */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import Modal from 'react-modal';

import { LastLocationProvider } from 'react-router-last-location';

import LoadingScreen from '../../components/LoadingIndicator';
import ScrollToTop from '../../components/ScrollToTop';
import EligibleRoute from '../App/EligibleRoute';
import PrivateRoute from '../App/PrivateRoute';
import AppointmentSaga from '../Appointment/saga';
import { logoutUser } from '../User/actions';
import Jobs from '../../components/Jobs';
import lazyLoad from '../../utils/react-lazy-load';

const FranchisePhase2 = lazyLoad(() =>
  import('../Appointment/franchise_phases/phase2'),
);
const HomePage = lazyLoad(() => import('../Home'));
const Appointment = lazyLoad(() => import('../Appointment'));
const MyAppointments = lazyLoad(() => import('../Appointment/appointments'));
const { InviteFriendsShare } = lazyLoad(() => import('../InviteFriends/share'));
const MyTherapists = lazyLoad(() => import('../Therapist/my_therapists'));
const OwnSqueeze = lazyLoad(() => import('../../components/OwnSqueeze'));
const Abandonmentpage = lazyLoad(() => import('../Abandonmentpage'));
const ErrorContent = lazyLoad(() => import('../../components/ErrorContent'));
const Philanthropy = lazyLoad(() => import('../../components/Philanthropy'));
const DesktopInstall = lazyLoad(() =>
  import('../../components/DesktopInstall'),
);
const TermsOfUse = lazyLoad(() => import('../../components/TermsOfUse'));
const PrivacyPolicy = lazyLoad(() => import('../../components/PrivacyPolicy'));
const ChangePassword = lazyLoad(() => import('../ChangePassword'));
const ForgotPassword = lazyLoad(() => import('../ForgotPassword'));
const ForgotPasswordLinkSent = lazyLoad(() =>
  import('../ForgotPassword/password-reset-link-sent'),
);
const ResetPassword = lazyLoad(() =>
  import('../ForgotPassword/reset-password'),
);
const Therapist = lazyLoad(() => import('../Therapist'));
const HowItWorks = lazyLoad(() => import('../../components/HowItWorks'));
const Gift = lazyLoad(() => import('../Gift'));
const Preferences = lazyLoad(() => import('../Preferences'));
const Membership = lazyLoad(() => import('../Membership'));
const Signup = lazyLoad(() => import('../Signup'));
const Login = lazyLoad(() => import('../Login'));
const Pricing = lazyLoad(() => import('../Pricing'));
const Print = lazyLoad(() => import('../Print'));
const AboutUs = lazyLoad(() => import('../../components/AboutUs'));
const Careers = lazyLoad(() => import('../../components/Careers'));
const Profile = lazyLoad(() => import('../Profile'));
const InviteFriends = lazyLoad(() => import('../InviteFriends'));
const Gratuity = lazyLoad(() => import('../Gratuity-Rating-Review'));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '450px',
  },
};

const App = () => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    const handleInvalidToken = e => {
      if (e.key === 'userToken' && e.oldValue && !e.newValue) {
        setIsLoggedOut(true);
      }
    };
    window.addEventListener('storage', handleInvalidToken);
  }, []);

  return (
    <BrowserRouter>
      <LastLocationProvider>
        <ScrollToTop>
          <Modal isOpen={isLoggedOut} style={customStyles}>
            <div
              className="NotifyTherapistModal"
              style={{ textAlign: 'center' }}
            >
              <button
                type="button"
                className="modal__close btn-icon icon js-modal-close"
                onClick={() => {
                  logoutUser();
                  window.location.reload();
                }}
              >
                <span className="screenreader">Close</span>
              </button>
              <div className="bubble">
                <p className="NotifyTherapistModal-bubble-text">
                  You were logged out!
                </p>
              </div>
              <div className="preg-modal__smiley">
                <i className="icon icon--smiley-blue-reflection" />
              </div>
            </div>
          </Modal>
          <Suspense fallback={<LoadingScreen hangTight />}>
            <Switch>
              <PrivateRoute
                path="/gratuity"
                toPath="/login"
                component={Gratuity}
              />
              <Route path="/appointment" component={Appointment} />
              <Route path="/therapist" component={Therapist} />
              <Route path="/membership" component={Membership} />
              <Route path="/gift" component={Gift} />
              <Route path="/register" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/print/:giftId" component={Print} />
              <PrivateRoute
                path="/preferences"
                toPath="/login"
                // eligibleOnly
                component={Preferences}
              />
              <PrivateRoute
                path="/appointments"
                toPath="/login"
                eligibleOnly
                component={MyAppointments}
              />
              <PrivateRoute
                path="/profile"
                toPath="/login"
                eligibleOnly
                component={Profile}
              />
              <PrivateRoute
                path="/my-therapists"
                toPath="/login"
                eligibleOnly
                component={MyTherapists}
              />
              <Redirect from="/thank-you" to="/#thank-you" />
              <EligibleRoute exact path="/" component={HomePage} />
              <EligibleRoute
                path="/abandonmentpage"
                component={Abandonmentpage}
              />
              <EligibleRoute exact path="/pricing" component={Pricing} />
              <EligibleRoute path="/about-us" component={AboutUs} />
              <EligibleRoute path="/franchising" component={OwnSqueeze} />
              <EligibleRoute path="/happy" component={OwnSqueeze} />
              <EligibleRoute path="/earn" component={OwnSqueeze} />
              <EligibleRoute path="/careers" component={Careers} />
              <EligibleRoute path="/jobs" component={Jobs} />
              <EligibleRoute path="/how-it-works" component={HowItWorks} />
              <EligibleRoute path="/privacy-policy" component={PrivacyPolicy} />
              <EligibleRoute path="/terms-of-use" component={TermsOfUse} />
              <EligibleRoute path="/philanthropy" component={Philanthropy} />
              <Redirect from="/joinus" to="/careers" />
              <EligibleRoute
                path="/invite/share"
                component={InviteFriendsShare}
              />
              <Route
                path="/invite"
                toPath="/login" // eligibleOnly
                component={InviteFriends}
              />
              <EligibleRoute path="/install" component={DesktopInstall} />

              <EligibleRoute
                exact
                path="/forgot-password"
                component={ForgotPassword}
              />
              <EligibleRoute
                exact
                path="/forgot-password/sent"
                component={ForgotPasswordLinkSent}
              />
              <EligibleRoute path="/password/reset" component={ResetPassword} />
              <EligibleRoute
                exact
                path="/change-password"
                component={ChangePassword}
              />
              <Route path="/*sweeps" component={FranchisePhase2} />
              <Route path="*" component={ErrorContent} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </LastLocationProvider>
    </BrowserRouter>
  );
};

const withSagaCheckGiftCode = injectSaga({
  key: 'checkGiftCode',
  saga: AppointmentSaga.watchCheckGiftCode,
});
export default compose(
  withRouter,
  withSagaCheckGiftCode,
)(App);
