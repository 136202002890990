/* export default {
  apiBase: process.env.API_BASE || 'https://staging.joinsqueeze.com/api',
  googleMapsApiKey:
    process.env.GOOGLE_MAPS_API_KEY ||
    'AIzaSyA5-ageiOZhTlth17viQoxBAZmcH8AKMTA',
  stripeApiKey:
    process.env.STRIPE_API_KEY || 'pk_test_A7dBTv79yXG23Nni03fts9Uw',
  segmentApiKey:
    process.env.SEGMENT_API_KEY || 'jmEceW2Eqc5x8r1Op12tLNBuU5lx32Nc',
  eligibilityAge: Number(process.env.ELIGIBILITY_AGE) || 13,
  minimumPurchaseAmountForGift: process.env.MINIMUM_PURCHASE_FOR_GIFT || 20,
};
 */

const Config = {
  apiBase: process.env.API_BASE,
  apiBasev2: process.env.API_BASE_V2,
  giftApiBase: process.env.GIFT_API_BASE,
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  stripeApiKey: process.env.STRIPE_API_KEY,
  stripePublicGiftApiKey: process.env.STRIPE_PUBLIC_GIFT_KEY,
  facebookAppId: process.env.FACEBOOK_APP_ID,
  segmentApiKey: process.env.SEGMENT_API_KEY,
  eligibilityAge: Number(process.env.ELIGIBILITY_AGE) || 13,
  minimumPurchaseAmountForGift: process.env.MINIMUM_PURCHASE_FOR_GIFT || 20,
  loginDeepLink: process.env.LOGIN_DEEP_LINK,
  sentryDsn: process.env.SENTRY_DSN,
  locationNames: process.env.LOCATION_NAMES,
  scottsdale: process.env.SCOTTSDALE_WIDGET_ID,
  northbrook: process.env.NORTHBROOK_WIDGET_ID,
  brentwood: process.env.BRENTWOOD_WIDGET_ID,
  cherrycreek: process.env.CHERRYCREEK_WIDGET_ID,
  rogers: process.env.ROGERS_WIDGET_ID,
  alamoheights: process.env.ALAMOHEIGHTS_WIDGET_ID,
  huntsville: process.env.HUNTSVILLE_WIDGET_ID,
  midtownatl: process.env.MIDTOWNATL_WIDGET_ID,
  boston: process.env.BOSTON_WIDGET_ID,
  providence: process.env.PROVIDENCE_WIDGET_ID,
  locationUrls: process.env.LOCATION_URLS,
  nextDoorApiKey: process.env.NEXT_DOOR_API_KEY,
  awsCloudFrontUrl: process.env.AWS_CLOUDFRONT_URL,
  klaviyoApiKey: process.env.KLAVIYO_API_KEY,
  recaptchaV3SiteKey: process.env.RECAPTCHA_SITE_KEY,
  lagunaniguel: process.env.LAGUNA_NIGUEL_WIDGET_ID,
  sanantonio: process.env.SANANTONIO_WIDGET_ID,
  fortworth: process.env.FORTWORTH_WIDGET_ID,
  assemblyrow: process.env.BOSTON_WIDGET_ID,
  westlake: process.env.WESTLAKE_WIDGET_ID,
  charlotte: process.env.CHARLOTTE_WIDGET_ID,
  tampa: process.env.TAMPA_WIDGET_ID,
  sarasota: process.env.SARASOTA_WIDGET_ID,
  westport: process.env.WESTPORT_WIDGET_ID,
  prosper: process.env.PROSPER_WIDGET_ID,
  arsenalyards: process.env.ARSENALYARDS_WIDGET_ID,
};

module.exports = Config;
