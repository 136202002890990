import PropType from 'prop-types';
import React from 'react';
import Config from '../../app.config';
import SvgIcon from '../SvgIcon';

const addLoaderStyle = loaderType => {
  if (loaderType === 'map') {
    return {
      background: 'rgba(255,255,255, 0.8)',
      position: 'fixed',
      color: 'black',
      fontWeight: 600,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    };
  }
  return {
    background: 'rgba(255,255,255, 0.8)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  };
};

function LoadingScreen(props) {
  const { hangTight, loaderMessage, loaderType } = props;
  if (!hangTight) {
    return (
      <div className="review-loader">
        <h3>{loaderMessage}</h3>
        <div className="rotate-animate">
          <SvgIcon icon="happy" size="normal" />
        </div>
      </div>
    );
  }
  return (
    <div className="loader-overlay">
      <div style={addLoaderStyle(loaderType)}>
        <div className="bubble">
          <p>{loaderMessage || 'Hang tight!'}</p>
        </div>
        <img
          src={`${Config.awsCloudFrontUrl}/images/about/LoadAnimation.gif`}
          alt="loading"
        />
      </div>
    </div>
  );
}

LoadingScreen.propTypes = {
  loaderType: PropType.string,
  loaderMessage: PropType.string,
  hangTight: PropType.string,
};

export default LoadingScreen;
