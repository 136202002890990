/* eslint-disable camelcase */
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import PromoMessage from '../../components/PromoMessage';
import { analytics } from '../../utils/common';
import {
  setSelectedLocation,
  setBuyRegularMembershipFlow,
  fetchNearestLocation,
} from '../Appointment/actions';
import makeSelectAppointment from '../Appointment/selectors';
import Mainmenu from '../Mainmenu';
import { logoutUser } from '../User/actions';
import { makeSelectUser } from '../User/selectors';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerMenuOpen: false,
      showBookMassageBtn: true,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleExitClick = this.handleExitClick.bind(this);
    this.handleExit = this.handleExit.bind(this);
  }

  static defaultProps = {
    showCloseButton: false,
    showBackButton: false,
    showHamburgerMenu: false,
    showLogo: false,
  };

  handleMenuClick() {
    this.setState({ hamburgerMenuOpen: !this.state.hamburgerMenuOpen });
  }

  handleExit() {
    if (this.props.handleExitClick) {
      return this.props.handleExitClick();
    }
    return this.props.history.push('/appointment/exit');
  }

  handleExitClick(href) {
    if (href.label === 'Become a Member') {
      this.props.fetchNearestLocation({}, true);
      this.props.setBuyRegularMembershipFlow(true);
    } else {
      this.props.fetchNearestLocation({}, false);
      this.props.setBuyRegularMembershipFlow(false);
    }
    const { hamburgerMenuOpen } = this.state;
    // eslint-disable-next-line
    hamburgerMenuOpen ? this.handleMenuClick() : this.handleExit();
  }

  handleBackClick() {
    this.props.history.goBack();
  }

  renderHeader({
    cName,
    hStyle,
    showBackButton,
    hamburgerMenuOpen,
    showHamburgerMenu,
    userData,
    showCloseButton,
    subHeadingAsMain,
    SubHeadingMobileInfo,
    showQuicklinks,
    showLogoOnPage,
    showLogo,
  }) {
    const { locationServices } = this.props.appointmentData;
    const { launch_status } = locationServices;
    const { pathname } = this.props.location;
    if (launch_status === 'phase3' && pathname.includes('more-info')) {
      this.state.showBookMassageBtn = false;
    }
    return (
      <div className={cName} style={hStyle}>
        <div className="leftSideBackbttn ownSqueeze">
          {showBackButton && (
            <div className="hdr-vue__back">
              <button
                className="hdr-vue__back-link"
                onClick={this.handleBackClick}
              >
                <i className="icon icon--arrow-left-white back-icon" />
              </button>
            </div>
          )}
          {showHamburgerMenu && (
            <button
              aria-label="hamburger-menu"
              className="btn-icon phone-only"
              onClick={this.handleMenuClick}
            >
              <i
                className={
                  hamburgerMenuOpen
                    ? 'icon icon--close-x-white phone-only'
                    : 'icon icon--hamburger-menu phone-only'
                }
              />
            </button>
          )}
        </div>

        <div className="centerLogo hide-phone">
          {!showLogo ? (
            <NavLink
              to="/"
              onClick={() => {
                // this.props.setSelectedLocation();
              }}
            >
              <i className="icon icon--logo" />
              <span className="screenreader">Squeeze</span>
            </NavLink>
          ) : (
            <div>
              {' '}
              <i className="icon icon--logo" />
              <span className="screenreader">Squeeze</span>
            </div>
          )}
        </div>
        <div className="centerLogo phone-only">
          {showLogoOnPage && (
            <NavLink to="/">
              <i className="icon icon--logo" />
              <span className="screenreader">Squeeze</span>
            </NavLink>
          )}

          {subHeadingAsMain && (
            <div>
              <h3 title={subHeadingAsMain} className="subHeadingConfirm">
                {subHeadingAsMain}
              </h3>
              <span>{SubHeadingMobileInfo}</span>
            </div>
          )}
        </div>

        <div className="rightSideMenubttn">
          <div className="hdr__nav">
            <div className="hdr__nav-menu">
              {showQuicklinks && (
                <div className="phone-only onlyOnMenu mobileTherapistFlowRightLinks logged-out-txt">
                  {isEmpty(userData) ? (
                    <div>
                      <NavLink
                        to="/login"
                        className="logged-out-txt"
                        onClick={() => {
                          analytics('Menu - Log In', null, true);
                        }}
                      >
                        Login &#8226;&nbsp;
                      </NavLink>
                      <NavLink
                        to="/register"
                        onClick={() => {
                          analytics('Menu - Sign Up', null, true);
                        }}
                      >
                        Sign Up
                      </NavLink>
                    </div>
                  ) : (
                    userData.first_name
                  )}
                </div>
              )}

              {hamburgerMenuOpen &&
                showHamburgerMenu && (
                  <div className="hide-phone logged-out-txt">
                    {isEmpty(userData) ? (
                      <NavLink
                        to="/login"
                        onClick={() => {
                          analytics('Menu - Log In', null, true);
                        }}
                      >
                        Log In
                      </NavLink>
                    ) : (
                      `Hi, ${
                        localStorage.getItem('userBasicDetails')
                          ? JSON.parse(localStorage.getItem('userBasicDetails'))
                              .first_name
                          : userData.first_name
                      }`
                    )}
                    <span> | </span>
                    {isEmpty(userData) ? (
                      <NavLink
                        to="/register"
                        onClick={() => {
                          analytics('Menu - Sign Up', null, true);
                        }}
                      >
                        Sign Up
                      </NavLink>
                    ) : (
                      // eslint-disable-next-line
                      <span
                        onClick={() => {
                          this.props.logoutUser(this.props.history);
                          this.handleExitClick();
                          // eslint-disable-next-line no-undef
                          analytics('Menu - Log Out', null, true);
                        }}
                      >
                        LOG OUT
                      </span>
                    )}
                  </div>
                )}

              {this.state.showBookMassageBtn &&
                !hamburgerMenuOpen &&
                showHamburgerMenu && (
                  <NavLink
                    aria-label="book-massage"
                    to="/appointment/locations"
                    id="bookMassage-from-header"
                    className="hdr__book-btn btn btn--transparent hide-phone"
                    onClick={() => {
                      analytics('Header - Book a Massage', null, true);
                      this.props.setBuyRegularMembershipFlow(false);
                      this.props.fetchNearestLocation({}, false);
                    }}
                  >
                    Book A Massage
                  </NavLink>
                )}
              {!hamburgerMenuOpen &&
                showHamburgerMenu && (
                  <button
                    className="btn-icon"
                    onClick={this.handleMenuClick}
                    aria-label="hamburger-menu"
                  >
                    <i className="icon icon--hamburger-menu hide-phone" />
                  </button>
                )}

              {(showCloseButton ||
                (showHamburgerMenu && hamburgerMenuOpen)) && (
                <button
                  aria-label="hamburger-menu-close"
                  className={
                    showHamburgerMenu && hamburgerMenuOpen
                      ? 'btn-icon hide-phone'
                      : 'btn-icon header-close-btn'
                  }
                  onClick={this.handleExitClick}
                >
                  <i className="icon icon--close-x-white" />
                </button>
              )}
              {this.props.additionalHeaderItems}
            </div>
            <div>
              {hamburgerMenuOpen &&
                showHamburgerMenu && (
                  <Mainmenu
                    {...this.props}
                    handleMenuClick={this.handleExitClick}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.setState({ showBookMassageBtn: true });
  }

  render() {
    const { hamburgerMenuOpen } = this.state;
    const {
      showHamburgerMenu,
      showCloseButton,
      showBackButton,
      subHeadingAsMain,
      showQuicklinks,
      SubHeadingMobileInfo,
      showLogoOnPage,
      showLogo,
    } = this.props;
    // eslint-disable-next-line
    const { userData } = this.props.user;
    // const cName = showBackButton ? 'HeaderMenuSq' : 'HeaderMenuSq';
    const cName = 'HeaderMenuSq';
    const hStyle = showBackButton ? {} : { textAlign: 'center' };
    const grayLineBg = subHeadingAsMain
      ? 'subHeadingTopHeader desktopHeading'
      : 'MainTopHeader';
    const SubHeadingMobileInfoClass = SubHeadingMobileInfo
      ? 'SubHeadingMobileInfo'
      : '';

    const headingBackground = subHeadingAsMain
      ? `${grayLineBg} ${SubHeadingMobileInfoClass}`
      : 'MainTopHeader';
    if (hamburgerMenuOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    const promoMsgProps = {
      hamburgerMenuOpen,
      urlArr: ['/', '/therapist/list', '/membership', '/gift', '/franchising'],
      // '/appointment/locations',
      redirectTo:
        //   'https://help.squeezemassage.com/hc/en-us/articles/360045531691',
        // tagLine: 'Click here to learn about our health & safety protocols',
        // linkText: 'Click Here',
        '/appointment/locations?purchase_funnel=false',
      tagLine: 'More locations opening soon!',
      linkText: 'Learn more',
      ariaLabel: 'more-locations',
    };
    return (
      <div className={hamburgerMenuOpen ? 'overflow-hidden nav-open' : ''}>
        <div>
          <div className={headingBackground}>
            <PromoMessage passedProps={promoMsgProps} />
            {this.renderHeader({
              cName,
              hStyle,
              showBackButton,
              hamburgerMenuOpen,
              showHamburgerMenu,
              userData,
              showCloseButton,
              subHeadingAsMain,
              SubHeadingMobileInfo,
              showQuicklinks,
              showLogoOnPage,
              showLogo,
            })}
            <div>
              {hamburgerMenuOpen &&
                showHamburgerMenu && (
                  <Mainmenu
                    {...this.props}
                    handleMenuClick={this.handleExitClick}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  appointmentData: PropTypes.bool,
  location: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showHamburgerMenu: PropTypes.bool,
  subHeadingAsMain: PropTypes.string,
  showQuicklinks: PropTypes.bool,
  showLogoOnPage: PropTypes.bool,
  userData: PropTypes.object,
  handleExitClick: PropTypes.func,
  history: PropTypes.object,
  SubHeadingMobileInfo: PropTypes.string,
  additionalHeaderItems: PropTypes.object,
  logoutUser: PropTypes.func,
  showLogo: PropTypes.bool,
  setBuyRegularMembershipFlow: PropTypes.func,
  fetchNearestLocation: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  appointmentData: makeSelectAppointment(),
});

const withConnect = connect(
  mapStateToProps,
  {
    logoutUser,
    setSelectedLocation,
    setBuyRegularMembershipFlow,
    fetchNearestLocation,
  },
);

export default compose(
  withRouter,
  withConnect,
  withLastLocation,
)(Header);
