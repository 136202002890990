/* eslint-disable camelcase */
import Config from 'app.config';
import dayjs from 'dayjs';
import { omitBy, sortBy, isEmpty } from 'lodash-es';
import { call, put, takeLatest, race, delay } from 'redux-saga/effects';
import { yieldErrorHandling } from 'utils/common';
import request, { buildRequestObj } from 'utils/request';
import { loadState } from '../../utils/localStorage_persist';

import {
  APPLY_REFERRAL,
  CANCEL_USER_APPOINTMENT,
  CHECK_GIFT_CODE,
  CREATE_USER_APPOINTMENT,
  FETCH_APPOINTMENTS,
  FETCH_AVAILABLE_THERAPISTS,
  FETCH_LOCATIONS,
  FETCH_LOCATION_MORE_INFO,
  FETCH_LOCATION_SERVICES,
  FETCH_MASSAGE_TYPES,
  FETCH_NEAREST_LOCATION,
  FETCH_STATUS,
  FETCH_USER_COMPLETED_APPOINTMENTS,
  FETCH_USER_PRESSURE,
  GET_IP_ADDRESS,
  GET_LIABILITY_WAIVER,
  RESERVE_APPOINTMENT_SAGA,
  RETRY_APPOINTMENT_CHARGE,
  SEND_SIGNATURE,
  SUGGESTED_TIP_AMOUNT,
  UPDATE_APPOINTMENT_TEXT,
  UPDATE_USER_PRESSURE,
} from './constants';

import {
  applyReferralSuccess,
  cancelUserAppointmentFailure,
  cancelUserAppointmentSuccess,
  checkGiftCodeFailure,
  checkGiftCodeSuccess,
  createUserAppointmentFailure,
  createUserAppointmentSuccess,
  fetchAppointmentsFailure,
  fetchAppointmentsSuccess,
  fetchAvailableTherapistsFailure,
  fetchAvailableTherapistsSuccess,
  fetchLocationMoreInfoFailure,
  fetchLocationMoreInfoSuccess,
  fetchLocationServicesFailure,
  fetchLocationServicesSuccess,
  fetchLocationsFailure,
  fetchLocationsSuccess,
  fetchMassageTypesFailure,
  fetchMassageTypesSuccess,
  fetchNearestLocationFailure,
  fetchNearestLocationSuccess,
  fetchStatusFailure,
  fetchStatusSuccess,
  fetchUserCompletedAppointmentsFailure,
  fetchUserCompletedAppointmentsSuccess,
  fetchUserPressureFailure,
  fetchUserPressureSuccess,
  getIpAddressFailure,
  getIpAddressSuccess,
  getLiabilityWaiverFailure,
  getLiabilityWaiverSuccess,
  getSuggestedTipAmountSuccess,
  retryAppointmentChargeFailure,
  retryAppointmentChargeSuccess,
  sendSignatureFailure,
  sendSignatureSuccess,
  setNotAvailableTillDate,
  updateAppointmentTextFailure,
  updateAppointmentTextSuccess,
  updateUserPressureFailure,
  updateUserPressureSuccess,
} from './actions';

export function* fetchLocationsSaga() {
  const reqObj = buildRequestObj('GET', {}, false);
  const reqUrl = `${Config.apiBasev2}/locations`;
  // const reqUrl = `https://api.squeezemassage.com/api/spa/locations`;

  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(fetchLocationsSuccess(result.items));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchLocationsFailure,
      'locations fetch failed',
    );
  }
}

function* watchFetchLocations() {
  yield takeLatest(FETCH_LOCATIONS, fetchLocationsSaga);
}

export function* fetchLocationServicesSaga(action) {
  const { locationId, isAuth } = action;
  const reqObj = buildRequestObj('GET', {}, !!isAuth);
  const reqUrl = `${Config.apiBasev2}/location/${locationId}/services`;
  // const reqUrl = "https://api-qa.squeezemassage.com/api/spa/location/1/services";
  try {
    const result = yield call(request, reqUrl, reqObj);
    result.services = result.services.filter(
      item => item.label !== 'Mini Squeeze',
    );
    yield put(fetchLocationServicesSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchLocationServicesFailure,
      'location services fetch failed',
    );
  }
}

function* watchFetchLocationServices() {
  yield takeLatest(FETCH_LOCATION_SERVICES, fetchLocationServicesSaga);
}

export function* fetchLocationMoreInfoSaga(action) {
  const reqObj = buildRequestObj('GET', {}, false);
  const { locationId } = action;
  const reqUrl = `${Config.apiBasev2}/location/${locationId}`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(fetchLocationMoreInfoSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchLocationMoreInfoFailure,
      'location details fetch failed',
    );
  }
}

function* watchFetchLocationMoreInfo() {
  yield takeLatest(FETCH_LOCATION_MORE_INFO, fetchLocationMoreInfoSaga);
}

export function* fetchMassageTypesSaga() {
  const reqObj = buildRequestObj('GET', {}, false);
  const reqUrl = `${Config.apiBasev2}/massage_types`;

  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(fetchMassageTypesSuccess(result.items));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchMassageTypesFailure,
      'massage types fetch failed',
    );
  }
}

function* watchFetchMassageTypes() {
  yield takeLatest(FETCH_MASSAGE_TYPES, fetchMassageTypesSaga);
}

export function* fetchAvailableTherapistsSaga(action) {
  const {
    /* eslint-disable */
    start_date_time,
    end_date_time,
    location_id,
    massage_type_id,
    pregnancy,
    therapist_id,
    service_id,
    nextAvailability,
    pre_live,
  } = action;
  const isPreLive = pre_live ? `&pre_live=${pre_live}` : '';
  const reqObj = buildRequestObj('GET', {}, true);
  const params = `location_id=${location_id}&service_id=${service_id ||
    1}&start_date_time=${start_date_time}&end_date_time=${end_date_time}${isPreLive}`;
  let reqUrl = nextAvailability
    ? `${Config.apiBasev2}/therapists/availability?${params}&future=true`
    : `${Config.apiBasev2}/therapists/availability?${params}`;
  if (pregnancy) {
    reqUrl = reqUrl.concat(`&pregnancy=${pregnancy}`);
  }
  if (therapist_id && therapist_id.length) {
    reqUrl = reqUrl.concat(`&therapist_id=${therapist_id}`);
  }

  try {
    const result = yield call(request, reqUrl, reqObj);
    const availableSlots = result.items;
    const notAvailableTillDate = result.notAvailableTillDate;
    yield put(fetchAvailableTherapistsSuccess(availableSlots));
    yield put(setNotAvailableTillDate(notAvailableTillDate));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchAvailableTherapistsFailure,
      'therapists fetch failed',
    );
  }
}

function* watchFetchAvailableTherapists() {
  yield takeLatest(FETCH_AVAILABLE_THERAPISTS, fetchAvailableTherapistsSaga);
}

export function* fetchUserPressureSaga() {
  const reqObj = buildRequestObj('GET', {}, true);
  const reqUrl = `${Config.apiBasev2}/user/preferences`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(fetchUserPressureSuccess(result.items));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchUserPressureFailure,
      'Could not fetch User Pressure',
    );
  }
}
function* watchFetchUserPressure() {
  yield takeLatest(FETCH_USER_PRESSURE, fetchUserPressureSaga);
}

export function* updateUserPressureSaga(action) {
  // eslint-disable-next-line
  const { customer_pressure, bun_in_the_oven, is_first_trimester } = action;
  const reqObj = buildRequestObj(
    'POST',
    { preferences: { customer_pressure, bun_in_the_oven, is_first_trimester } },
    true,
  );
  const reqUrl = `${Config.apiBasev2}/user/preferences`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(updateUserPressureSuccess(result.items));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      updateUserPressureFailure,
      'Could not Update User Pressure',
    );
  }
}

function* watchUpdateUserPressureSaga() {
  yield takeLatest(UPDATE_USER_PRESSURE, updateUserPressureSaga);
}

export function* checkGiftCodeSaga(action) {
  // To-Do: Create a util function to get data from local storage
  // values from local storage
  const {
    amount,
    slot,
    coupon,
    purchaseType,
    location_id,
    start_date_time,
    booker_service_id,
    qty,
    membership_length,
  } = action;

  const user_id = localStorage.getItem('userId');
  const persistedState = loadState();
  const netAmount =
    amount ||
    persistedState.appointment.appointmentNetValue ||
    persistedState.gift.netTotal;

  const promoCodeReqObject = omitBy(
    {
      code: coupon,
      product: purchaseType,
      location: location_id || 1,
      start_at: slot,
      user_id,
      amount: netAmount,
      booker_service_id,
      // qty,
      // membership_length,
    },
    val => !val,
  );

  const reqObj = buildRequestObj('POST', promoCodeReqObject, true);
  const reqUrl = `${Config.apiBasev2}/coupon/validate`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(checkGiftCodeSuccess({ ...result, code: coupon }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      checkGiftCodeFailure,
      'gift code check failed',
    );
  }
}
function* watchCheckGiftCode() {
  yield takeLatest(CHECK_GIFT_CODE, checkGiftCodeSaga);
}

export function* createUserAppointmentSaga(action) {
  const { appointmentObj } = action;
  const user_id = localStorage.getItem('userId');
  
  const appointmentReqObj = buildRequestObj(
    'POST',
    {
      ...appointmentObj,
    },
    true,
  );
  const appointmentReqUrl = `${Config.apiBasev2}/user/${user_id}/appointment/confirm`;

  const timeout = 180000;

  try {
    localStorage.removeItem('referral_link');

    const { response, timeoutError } = yield race({
      response: call(request, appointmentReqUrl, appointmentReqObj),
      timeoutError: delay(timeout),
    });

    if (timeoutError) {
      throw new Error('Request timed out');
    }

    const appointmentId = response.id;
    const amount = response.non_member_price;
    Grin = window.Grin || (window.Grin = []);
    Grin.push(['conversion', amount, { order_number: appointmentId }]);
    
    yield put(createUserAppointmentSuccess({ success: true, items: response }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      createUserAppointmentFailure,
      'User appointment creation failed',
    );
  }
}

function* watchCreateUserAppointment() {
  yield takeLatest(CREATE_USER_APPOINTMENT, createUserAppointmentSaga);
}

export function* updateAppointmentTextSaga(action) {
  // eslint-disable-next-line
  const { notificationObject } = action;
  const reqObj = buildRequestObj(
    'POST',
    {
      ...notificationObject,
    },
    true,
  );
  const reqUrl = `${Config.apiBasev2}/appointment/text`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(updateAppointmentTextSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      updateAppointmentTextFailure,
      'user appointment text notifications failed',
    );
  }
}

function* watchUpdateAppointmentTextSaga() {
  yield takeLatest(UPDATE_APPOINTMENT_TEXT, updateAppointmentTextSaga);
}

export function* fetchUserCompletedAppointmentsSaga(action) {
  const reqObj = buildRequestObj('GET', {}, true);
  const reqUrl = `${Config.apiBasev2
    }/appointments?status=completed&order_by=desc`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(fetchUserCompletedAppointmentsSuccess(result.items));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchUserCompletedAppointmentsFailure,
      'user appointments fetch failed',
    );
  }
}

function* watchFetchUserCompletedAppointments() {
  yield takeLatest(
    FETCH_USER_COMPLETED_APPOINTMENTS,
    fetchUserCompletedAppointmentsSaga,
  );
}

// Fetch appointments by status
export function* fetchAppointmentsSaga(action) {
  const reqObj = buildRequestObj('GET', {}, true);
  const reqUrl = `${Config.apiBasev2}/appointments?status=upcoming`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(
      fetchAppointmentsSuccess(
        sortBy(result.items, appointment => dayjs(appointment.start_date_time)),
      ),
    );
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchAppointmentsFailure,
      'user completed appointments fetch failed',
    );
  }
}
function* watchFetchAppointments() {
  yield takeLatest(FETCH_APPOINTMENTS, fetchAppointmentsSaga);
}

// get status
export function* fetchStatusSaga() {
  const reqObj = buildRequestObj('GET', {}, true);
  let reqUrl = `${Config.apiBasev2}/status`;
  if (
    window.localStorage.getItem('isBuyingMembership') ||
    window.localStorage.getItem('isBuyingFoundingMembership')
  ) {
    reqUrl = `${Config.apiBasev2}/status?flow=${encodeURIComponent(
      'membership-flow',
    )}`;
  }
  if (window.localStorage.getItem('isBuyingGift')) {
    reqUrl = `${Config.apiBasev2}/status?flow=${encodeURIComponent(
      'gift-flow',
    )}`;
  }
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(fetchStatusSuccess(result));
    // const Result_sentry = JSON.stringify(result);
    // Sentry.captureMessage(Result_sentry);
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchStatusFailure,
      'user status fetch failed',
    );
  }
}

function* watchFetchStatus() {
  yield takeLatest(FETCH_STATUS, fetchStatusSaga);
}

export function* cancelUserAppointmentSaga(action) {
  const { appointment_id } = action;
  const reqObj = buildRequestObj('PUT', {}, true);
  const reqUrl = `${Config.apiBasev2}/appointment/${appointment_id}/cancel`;

  try {
    yield call(request, reqUrl, reqObj);
    yield put(cancelUserAppointmentSuccess({ success: true }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      cancelUserAppointmentFailure,
      'user appointment cancellation failed',
    );
  }
}

function* watchCancelUserAppointment() {
  yield takeLatest(CANCEL_USER_APPOINTMENT, cancelUserAppointmentSaga);
}

export function* retryAppointmentChargeSaga(action) {
  const { spabooker_id } = action;
  const reqObj = buildRequestObj('POST', { spabooker_id }, true);
  const reqUrl = `${Config.apiBasev2}/appointment/retry`;
  try {
    const response = yield call(request, reqUrl, reqObj);
    const chargeResponse = response.appointments && response.appointments[0];
    const result = { ...chargeResponse, success: true };
    yield put(retryAppointmentChargeSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      retryAppointmentChargeFailure,
      'appointment charge retry',
    );
  }
}

function* watchRetryAppointmentCharge() {
  yield takeLatest(RETRY_APPOINTMENT_CHARGE, retryAppointmentChargeSaga);
}

export function* reserveAppointmentSaga(action) {
  const { appointmentObj } = action;
  const id = localStorage.getItem('userId');
  const {
    location_id,
    service_id,
    therapist_id,
    slot_time,
    pressure,
    similar_therapist,
  } = appointmentObj;
  const reqObj = buildRequestObj(
    'POST',
    {
      location_id,
      service_id,
      therapist_id,
      slot_time,
    },
    true,
  );
  const reqUrl = `${Config.apiBasev2}/user/${id}/appointment/reserve`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    const { bookingID } = result;
    const creatObj = {
      booking_id: bookingID,
      pressure,
      similar_therapist,
    };
    yield createUserAppointmentSaga(creatObj);
  } catch (error) {
    yield yieldErrorHandling(
      error,
      createUserAppointmentFailure,
      'user appointment reservation failed',
    );
  }
}

function* watchReserveAppointmentSaga() {
  yield takeLatest(RESERVE_APPOINTMENT_SAGA, reserveAppointmentSaga);
}

export function* applyReferralTrack() {
  const referral_link = localStorage.getItem('referral_link');
  const reqObj = buildRequestObj('POST', { referral_link }, true);
  try {
    const reqUrl = `${Config.apiBasev2}/user/referral/validate`;
    const result = yield call(request, reqUrl, reqObj);
    yield put(applyReferralSuccess(result));
  } catch (error) {
    // console.log(`error!!!`, error);
  }
}

function* watchApplyReferral() {
  yield takeLatest(APPLY_REFERRAL, applyReferralTrack);
}

export function* fetchNearestLocationSaga(action) {
  const { ipAddress, latLng, isBuyingRegularMembershipFlow} = action;
  const reqObj = buildRequestObj('GET', {}, false);
  let reqUrl = `${Config.apiBasev2}/nearest-location`;
  if (!isEmpty(latLng)) {
    const { lat, lng } = latLng;
    reqUrl = lat && lng ? 
      `${Config.apiBasev2}/nearest-location?lat=${lat}&long=${lng}` : 
      `${Config.apiBasev2}/nearest-location` ;
  } else if (ipAddress) {
    reqUrl = !isEmpty(ipAddress) ? 
      `${Config.apiBasev2}/nearest-location?ipAddress=${ipAddress}` : 
      `${Config.apiBasev2}/nearest-location`;
  }
  try {
    const result = yield call(request, reqUrl, reqObj);
    const nearstLocation = result;
    if (isBuyingRegularMembershipFlow) {
      const activeLocations = nearstLocation.items.filter((loc, index) => {
        if (loc.launch_status === null && loc.is_active === 1) {
          loc.serialNo = (index + 1);
          return loc;
        }
      });
      activeLocations.forEach((loc, index) => {
        loc.serialNo = (index + 1);
      });
      yield put(fetchNearestLocationSuccess({ items: activeLocations, length: activeLocations.length }));
    } else {
      let indx = 0;
      nearstLocation.items.map(location => {
        location.serialNo = indx += 1;
        return location;
      });
      yield put(fetchNearestLocationSuccess(nearstLocation));
    }
    
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchNearestLocationFailure,
      'fetch nearest location failed',
    );
  }
}

function* watchNearestLocation() {
  yield takeLatest(FETCH_NEAREST_LOCATION, fetchNearestLocationSaga);
}

export function* getIpAddress() {
  const reqObj = buildRequestObj('GET', {}, false);
  const reqUrl = 'https://api.ipify.org/?format=json';
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(getIpAddressSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      getIpAddressFailure,
      'Failed to fetch IP',
    );
  }
}

function* watchGetIpAddress() {
  yield takeLatest(GET_IP_ADDRESS, getIpAddress);
}

export function* getLiabilityWaiverSaga(action) {
  const reqObj = buildRequestObj('GET', {}, true);
  const reqUrl = `${Config.apiBasev2}/liability_waiver`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(getLiabilityWaiverSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      getLiabilityWaiverFailure,
      'user liability waiver failed',
    );
  }
}

function* watchGetLiabilityWaiver() {
  yield takeLatest(GET_LIABILITY_WAIVER, getLiabilityWaiverSaga);
}

export function* sendSignatureSaga(action) {
  const { imageString } = action;
  const reqObj = buildRequestObj('POST', { imageString }, true);
  try {
    const reqUrl = `${Config.apiBasev2}/liability_waiver`;
    const result = yield call(request, reqUrl, reqObj);
    yield put(sendSignatureSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      sendSignatureFailure,
      'send user Signature failed',
    );
  }
}

function* watchSendSignature() {
  yield takeLatest(SEND_SIGNATURE, sendSignatureSaga);
}

export function* getSuggestedTipAmount() {
  const reqObj = buildRequestObj('GET', {}, true);
  const reqUrl = `${Config.apiBasev2}/suggested-tip-amount`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(getSuggestedTipAmountSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      getSuggestedTipAmountSuccess,
      'Failed to get user tip amount!',
    );
  }
}

function* watchGetSuggestedTipAmount() {
  yield takeLatest(SUGGESTED_TIP_AMOUNT, getSuggestedTipAmount);
}

export default {
  watchFetchLocations,
  watchFetchLocationServices,
  watchFetchLocationMoreInfo,
  watchFetchMassageTypes,
  watchFetchAvailableTherapists,
  watchCheckGiftCode,
  watchCreateUserAppointment,
  watchFetchUserPressure,
  watchUpdateUserPressureSaga,
  watchUpdateAppointmentTextSaga,
  watchFetchUserCompletedAppointments,
  watchFetchAppointments,
  watchCancelUserAppointment,
  watchRetryAppointmentCharge,
  watchReserveAppointmentSaga,
  watchApplyReferral,
  watchFetchStatus,
  watchNearestLocation,
  watchGetLiabilityWaiver,
  watchSendSignature,
  watchGetSuggestedTipAmount,
  watchGetIpAddress,
};
