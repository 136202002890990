import React from 'react';

const Jobs = () => (
  <React.Fragment>
    <div className="career-wrapper">
      <nav className="career-nav">
        <div className="career-nav-inner">
          <a href="/" className="career-logo">
            <img
              src="https://squeeze-public.s3.us-west-2.amazonaws.com/email/images/logo.png"
              alt="logo"
            />
          </a>
          <h4 className="career-brand-text">CAREERS</h4>
        </div>
      </nav>
      <div>
        <div className="career-container" />
        <iframe
          title="Jobs list"
          name="resumator-job-frame"
          id="resumator-job-frame"
          className="resumator-advanced-widget"
          src="//squeeze.applytojob.com/apply/jobs/"
          width="100%"
          height="95%"
          frameBorder="0"
          allowTransparency="true"
        />
      </div>
      {/* <footer className="career-footer" /> */}
    </div>
  </React.Fragment>
);

export default Jobs;
